import { forwardRef } from "react";
import buttonClasses from "../Button.module.scss";
import cn from "classnames";
import { useEffect } from "react";

import classes from "./SubmitButton.module.scss";

function SubmitButton(props, ref) {
  const colorVariantClass = props.color
    ? `color${props.color.charAt(0).toUpperCase() + props.color.slice(1)}`
    : "";
  const bgColorVariantClass =
    props.color && props.bg
      ? `bg${props.color.charAt(0).toUpperCase() + props.color.slice(1)}`
      : "";

  // icon images must be in "button-icon-iconName" format
  const iconClassPart = props.icon
    ? props.icon.split(".")[0].replace("button-icon-", "")
    : "";
  const iconVariantClass = props.icon
    ? `icon${iconClassPart.charAt(0).toUpperCase() + iconClassPart.slice(1)}`
    : "";

  const buttonUtilityClasses = {
    [buttonClasses.small]: props.small,
    [buttonClasses[colorVariantClass]]: colorVariantClass,
    [buttonClasses.bg]: props.bg && !bgColorVariantClass,
    [buttonClasses[bgColorVariantClass]]: bgColorVariantClass,
    [buttonClasses.icon]: props.icon,
    [buttonClasses[iconVariantClass]]: iconVariantClass,
  };

  const ButtonInner = forwardRef((props, ref) => {
    return (
      <button
        disabled={props.loading || props.disabled}
        type="submit"
        ref={ref}
        className={cn(
          buttonClasses.button,
          classes.submitButton,
          buttonUtilityClasses,
          props.className
        )}
      >
        {props.loading && (
          <div className={classes.spinner}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
        {props.children}
      </button>
    );
  });

  ButtonInner.displayName = "ButtonInner";

  return (
    <ButtonInner className={props.className} loading={props.loading}>{props.children}</ButtonInner>
  );
}

export default SubmitButton;
