import { TextLink } from "../../elements";
import cn from 'classnames';

import classes from "./FooterMenu.module.scss";

export default function FooterMenu(props) {
    return (
        <div className={classes.footerMenu}>
            <div className={classes.box}>
                <p className={classes.heading}>Office</p>
                <div className={classes.content}>
                    <address>
                        <p>
                            ul. Lubicz 17G
                            <br />
                            31-503 Kraków, Poland
                        </p>
                    </address>
                </div>
            </div>
            <div className={classes.box}>
                <p className={classes.heading}>Contact</p>
                <div className={classes.content}>
                    <address>
                        <p>
                            <a href="tel:+48721067878">+48 721 067 878</a>
                        </p>
                    </address>
                    <span>
            <TextLink href="/contact" className={classes.textLink}>Get in touch</TextLink>
          </span>
                </div>
            </div>
            <div className={classes.box}>
                <p className={classes.heading}>Careers</p>
                <div className={classes.content}>
                    <p>We’re looking for talented, passionate folks to&nbsp;join our team.</p>
                    <TextLink href="/careers" className={classes.textLink}>Explore Careers</TextLink>
                </div>
            </div>
            <div className={cn(classes.box, classes.socialsBox)}>
                <p className={classes.heading}>Follow</p>
                <div className={classes.content}>
                    <ul>
                        <li><a href="https://www.facebook.com/commercemediatech/" target="_blank" rel="noreferrer">Facebook</a></li>
                        <li><a href="https://www.instagram.com/commercemediatech/" target="_blank" rel="noreferrer">Instagram</a></li>
                        <li><a href="https://www.linkedin.com/company/commercemediatech/" target="_blank" rel="noreferrer">Linkedin</a></li>
                        <li><a href="https://twitter.com/com_media_tech" target="_blank" rel="noreferrer">Twitter</a></li>
                        <li><a href="https://www.youtube.com/@commercemediatech/" target="_blank" rel="noreferrer">Youtube</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
