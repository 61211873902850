import { forwardRef } from "react";
import Link from "next/link";
import classes from "./TextLink.module.scss";
import cn from 'classnames';

function TextLink(props, ref) {
  return (
    (<Link
      href={props.href}
      passHref
      ref={ref}
      onClick={props.onClick}
      className={cn(classes.textLink, props.className, {
          [classes.textLinkToLeft]: props.toLeft === true
      })}>

      {props.children}

    </Link>)
  );
}

export default forwardRef(TextLink);
