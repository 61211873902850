import Image from "next/image";

import React, { useState, useEffect } from "react";
import { gsap } from "../../../gsap";
import cn from "classnames";
import classes from "./FooterBadge.module.scss";
import badgeStyles from "../BadgeStyles.module.scss";
// import sayhelloBadgeImg from "/public/images/badge-say-hello.svg";
import startoverBadgeImg from "/public/images/badge-start-over.svg";
import { scrollToEase } from "/helpers/constants";

export default function FooterBadge({ fixed, atBottom }) {
  const [badgeImage, setBadgeImage] = useState(startoverBadgeImg);
  const handleClick = (e) => {
    e.preventDefault();

    gsap.to(window, 2, {
        scrollTo: { y: 0, offsetY: 0, ease: scrollToEase }
    });
  };

  return (
    <div
      className={cn(badgeStyles.badge, classes.wrapper)}
      onClick={handleClick}
    >
      <div className={cn(badgeStyles.badgeImageWrapper, classes.imageWrapper)}>
        <Image src={badgeImage} fill alt="" />
      </div>
    </div>
  );
}
