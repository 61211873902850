import cn from "classnames";
import classes from "./Checkbox.module.scss";
import {createElement} from "react";

export default function Checkbox({
  placeholder,
  id,
  name,
  type,
  register,
  required,
  errors,
  onChange,
  children,
  onFocus,
  onBlur,
}) {
  let childrenObj = [children];

  if (required) {
    childrenObj.push('*');
  }

  const displayedLabel = createElement("span", {}, childrenObj);

  return (
    <div className={cn(classes.inputWrapper, { [classes.error]: errors })}>
      <>
      <input
        {...register(name)}
        id={id}
        name={name}
        type="checkbox"
        // onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <label htmlFor={id}>{displayedLabel}</label>

      {errors && <div className={classes.errorsWrapper}>{errors.message}</div>}
      </>
    </div>
  );
}
