import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	currentSection: 0,
};

export const careersSlice = createSlice({
	name: "careers",
	initialState,
	reducers: {
		setCurrentSection: (state, action) => {
			state.currentSection = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setCurrentSection } = careersSlice.actions;
export default careersSlice.reducer;
