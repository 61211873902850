import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  animateContactArrow: false,
  jobsSectionAnimated: false,
  contactSectionAnimated: false,
  topBarDisplayed: false
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setAnimateContactArrow: (state, action) => {
      state.animateContactArrow = action.payload;
    },
    setJobsSectionAnimated: (state, action) => {
      state.jobsSectionAnimated = action.payload;
    },
    setContactSectionAnimated: (state, action) => {
      state.contactSectionAnimated = action.payload;
    },
    setTopBarDisplayed: (state, action) => {
      state.topBarDisplayed = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAnimateContactArrow,
  setJobsSectionAnimated,
  setContactSectionAnimated,
  setTopBarDisplayed,
} = uiSlice.actions;
export default uiSlice.reducer;
