import { useRouter } from "next/router";
import Link from "next/link";
import React, { Children } from "react";
import cn from "classnames";
import classes from "./ActiveLink.module.scss";

const ActiveLink = ({ children, className: extraClasses, href, ...props }) => {
  const { asPath } = useRouter();

  // removing / at the end of the address to catch both routes (test/, test)
  const className =
    asPath.split("#")[0].replace(/\/$/, "") === href.replace(/\/$/, "") ||
    asPath.split("#")[0].replace(/\/$/, "") === props.as
      ? `${cn(classes.link, extraClasses, classes.active)}`
      : `${cn(classes.link, extraClasses)}`;

  return (
    <Link passHref href={href} className={className}>
      <span>{children}</span>
    </Link>
  );
};

export default ActiveLink;
