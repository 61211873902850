// SSR doesn't work with ES Modules so we need to import the UMD files instead
import { gsap } from "gsap/dist/gsap.js";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin.js";

// configure/register once we're running in the browser
if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollToPlugin);
}

// export anything that you might need a reference to
export * from "gsap/dist/gsap";
