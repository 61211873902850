import cn from "classnames";
import classes from "./Textarea.module.scss";

export default function Textarea({
  placeholder,
  id,
  name,
  type,
  register,
  required,
  errors,
  onFocus,
  onBlur
}) {
  const displayedLabel = required ? `${placeholder}*` : placeholder;

  return (
    <div className={cn(classes.inputWrapper, { [classes.error]: errors })}>
      <textarea
        {...register(name)}
        id={id}
        name={name}
        type={type || "text"}
        placeholder={displayedLabel}
        onFocus={onFocus}
        onBlur={onBlur}
      ></textarea>
      <label htmlFor={id}>{displayedLabel}</label>

      {errors && <div className={classes.errorsWrapper}>{errors.message}</div>}
    </div>
  );
}
