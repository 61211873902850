import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    questionNumber: 0,
    timerStatus: 'STARTED',
  }

export const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    setQuestionNumber: (state, action) => {
        state.questionNumber = action.payload
    },
    startTimer: (state) => {
        state.timerStatus = 'STARTED';
    },
    stopTimer: (state) => {
        state.timerStatus = 'STOPPED';
    }
  },
})

// Action creators are generated for each case reducer function
export const { setQuestionNumber, startTimer, stopTimer } = quizSlice.actions
export default quizSlice.reducer
