import Link from "next/link";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import throttle from "lodash/throttle";

import { Button, ActiveLink, Dropdown } from "../../elements";

import classes from "./MainNavigation.module.scss";
import { useSelector } from "react-redux";

//images
import codewiseLogo from "../../../public/images/cmtech-logo.svg";

const MainNavigation = (props) => {
  const [isFixed, setIsFixed] = useState(false);
  const homeBadgeLoaded = useSelector((state) => state.badge.homeBadgeLoaded);
  const topBarDisplayed = useSelector((state) => state.ui.topBarDisplayed);

  const handleScroll = (e) => {
    if (window.scrollY > (topBarDisplayed ? 42 : 0)) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  const throttledScrollHandler = throttle(handleScroll, 200);

  useEffect(() => {
    throttledScrollHandler();

    window.addEventListener("scroll", throttledScrollHandler, {
      passive: true,
    });

    // cleanup
    return () => {
      window.removeEventListener("scroll", throttledScrollHandler);
    };
  }, [isFixed, throttledScrollHandler]);

  const headerRef = useRef(null);

  return (
    <header
      ref={headerRef}
      className={cn(classes.header, {
        [classes.headerScrolled]: isFixed,
        [classes.headerUnderTopbar]: topBarDisplayed,
        [classes.headerShownAfterLoader]: homeBadgeLoaded,
      })}
      id="header"
    >
      <div className={classes.inner}>
        <Link href="/">
          <Image
            src={codewiseLogo}
            alt="Commerce Media Tech Logo"
            className={classes.logo}
            priority
          />
        </Link>
        {props.menuItems && (
          <nav>
            <ul>
              {props.menuItems.map((item, index) => {
                return item.dropdownItems ? (
                  <li key={item.label + index}>
                    <Dropdown title={item.label}>
                      {item.dropdownItems.map((item, index) => (
                        <ActiveLink key={item.label + index} href={item.link}>
                          {item.label}
                        </ActiveLink>
                      ))}
                    </Dropdown>
                  </li>
                ) : (
                  <li key={index}>
                    <ActiveLink href={item.link}>{item.label}</ActiveLink>
                  </li>
                );
              })}
            </ul>
          </nav>
        )}
        {props.menuButtons && (
          <div className={classes.buttonWrapper}>
            {props.menuButtons.map((item, index) => (
              <Button
                small
                href={item.link}
                key={index}
                bg={item.bg}
                icon={item.icon}
              >
                {item.label}
              </Button>
            ))}
          </div>
        )}
      </div>
    </header>
  );
};
// MainNavigation.whyDidYouRender = true;
export default MainNavigation;
