import cn from "classnames";
import classes from "./RadioGroup.module.scss";
import { Fragment} from "react";

export default function RadioGroup({
  className: extraClasses,
  placeholder,
  id,
  name,
  type,
  register,
  required,
  errors,
  label,
  values,
  onChange,
  children,
  onFocus,
  onBlur,
}) {
  return (
    <div className={cn(classes.inputWrapper, classes.big, {[classes.error]: errors}, extraClasses)}>
      <div className={classes.label}>
        { children }
      </div>
      {values.map(item => (
        <Fragment key={id + item.value}>
          <input
            {...register(name)}
            id={id + item.value}
            name={name}
            value={item.value}
            type="radio"
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          <label htmlFor={id + item.value}>{ item.label }</label>
        </Fragment>
      ))}
      {errors && <div className={classes.errorsWrapper}>{errors.message}</div>}
    </div>
  );
}
