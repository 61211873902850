import { configureStore } from "@reduxjs/toolkit";
// import counterReducer from './ui/ui-store'
import badgeReducer from "./ui/badge-store";
import uiReducer from "./ui/ui-store";
import internshipReducer from "./ui/internship-store";
import homeReducer from "./ui/home-store";
import careersReducer from "./ui/careers-store";
import quizReducer from "./ui/quiz-store";
import jobReducer from "./ui/job-store";
import devoxQuizReducer from "./ui/devox-quiz-store";

export const store = configureStore({
	reducer: {
		badge: badgeReducer,
		ui: uiReducer,
		internship: internshipReducer,
		home: homeReducer,
		careers: careersReducer,
		quiz: quizReducer,
		devoxQuiz: devoxQuizReducer,
		job: jobReducer,
	},
});
