import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	activeSectionIndex: 0,
};

export const jobSlice = createSlice({
	name: "careers",
	initialState,
	reducers: {
		setActiveSectionIndex: (state, action) => {
			state.activeSectionIndex = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setActiveSectionIndex } = jobSlice.actions;
export default jobSlice.reducer;
