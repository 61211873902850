import { useEffect, useRef, useState } from "react";
import Link from "next/link";
import { Button } from "../elements";
import { motion } from "framer-motion";

import classes from "./CookieBox.module.scss";
import { useIsSmall, useIsMedium } from "../../hooks/useMediaQuery";

import { useCookies } from "react-cookie";

export default function CookieBox(props) {
  const consentCookieName = "newCookieAccept";
  const isMobile = useIsMedium();
  const [cookies, setCookie, removeCookie] = useCookies([consentCookieName]);
  const [showCookieBox, setShowCookieBox] = useState();

  useEffect(() => {
    if (
      cookies[consentCookieName] === "true" ||
      cookies[consentCookieName] === "false"
    ) {
      setShowCookieBox(false);
    } else {
      setShowCookieBox(true);
    }
  }, [cookies]);

  const handleCookieBoxButtonAccept = (e) => {
    e.preventDefault();
    setCookie(consentCookieName, "true", {
      maxAge: 3600 * 24 * 365, // expires after 1 year
    });
  };

  const handleCookieBoxButtonNotNow = (e) => {
    e.preventDefault();
    setCookie(consentCookieName, "false", {
      maxAge: 3600 * 24 * 7, // expires after a week
    });
  };

  const box = useRef();

  const cookieBoxMobileAnimation = {
    hidden: {
      opacity: 0,
      x: 50,
    },
    visible: {
      opacity: 1,
      x: "-50%",
      transition: {
        delay: 1.5,
      },
    },
  };

  const cookieBoxAnimation = {
    hidden: {
      opacity: 0,
      x: 50,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 1.5,
      },
    },
  };

  return (
    <>
      {showCookieBox && (
        <motion.div
          initial="hidden"
          animate="visible"
          variants={isMobile ? cookieBoxMobileAnimation : cookieBoxAnimation}
          className={classes.cookieBox}
          ref={box}
          layout="position"
        >
          <p>
            We use cookies to serve more relevant content to you. For more
            information, please review documents{" "}
            <Link href="/cookies-policy">here.</Link>
          </p>
          <div className={classes.cookieBoxButtons}>
            <Button
              small="true"
              onClick={handleCookieBoxButtonAccept}
              className={classes.cookieBoxButton}
            >
              Accept
            </Button>
            <Button
              small="true"
              onClick={handleCookieBoxButtonNotNow}
              className={classes.cookieBoxButton}
            >
              Not now
            </Button>
          </div>
        </motion.div>
      )}
    </>
  );
}
