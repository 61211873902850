// don't put slash at the end of route path. router.route displays paths without '/', router.asPath adds '/'

/* 
type MenuItem = {
    label: string,
    link: string,
    dropdownItems?: MenuItem[]
}

type MenuButton = {
    label: string,
    link: string
}

type MenuItems = MenuItem[]

type MenuButtons = MenuButton[]
*/

// import MorphsHome from "./morphs/MorphsHome";
// import MorphsContact from "./morphs/MorphsContact";

import dynamic from "next/dynamic";

const MorphsHome = dynamic(
  () => import("/components/layout/morphs/MorphsHome"),
  { ssr: false }
);
const MorphsContact = dynamic(
  () => import("/components/layout/morphs/MorphsContact"),
  { ssr: false }
);

// const MorphsChrimstmas = dynamic(
//   () => import("/components/christmas-task/MorphsChrimstmas"),
//   {
//     ssr: false,
//   }
// );

const routes = {
  default: {
    menuItems: [
      { label: "Products", link: "/products" },
      { label: "Careers", link: "/careers",
        // dropdownItems: [
        //   {
        //     label: "Job offers",
        //     link: "/careers",
        //   },
        //   {
        //     label: "Internship",
        //     link: "/internship",
        //   },
        // ],
      },
      { label: "Contact us", link: "/contact" },
    ],
    menuButtons: [{ label: "See job offers", link: "/careers#jobs" }],
    morphs: MorphsHome
  },
  "/": {
    bodyClass: "home",
  },
  "/404": {
    bodyClass: "page404",
    morphs: null
  },
  "/careers/[slug]": {
    bodyClass: "jobDescription",
  },
  "/internship": {
    bodyClass: "internships",
  },
  "/internship-info": {
    bodyClass: "internships",
  },
  "/christmas-task": {
    menuItems: [
      { label: "O co chodzi?", link: "#o-co-chodzi" },
      { label: "Kogo wspieramy", link: "#kogo-wspieramy" },
      { label: "Twoje zadanie", link: "#twoje-zadanie" },
    ],
    menuButtons: [
      // {
      //   label: "Weź udział!",
      //   link: "#twoje-zadanie",
      //   bg: "black",
      //   icon: "button-icon-register.svg",
      // },
    ],
  },
  "/contact": {
    morphs: MorphsContact
  },
  "/lead-up": {
    menuItems: [
      { label: "O wydarzeniu", link: "#o-wydarzeniu" },
      { label: "Dlaczego warto", link: "#dlaczego-warto" },
      { label: "Idealny uczestnik", link: "#ty" },
      { label: "Plan wydarzenia", link: "#agenda" },
    ],
    menuButtons: [
      {
        label: "Zarejestruj się",
        link: "#rejestracja",
        bg: "black",
        icon: "button-icon-register.svg",
      },
    ],
  },
};

export default routes;
