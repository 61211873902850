import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isAtPreBottom: false,
    test: 'some value',
    loaderShown: true,  // TODO 0 change to false
    homeBadgeLoaded: true,  // TODO 0 change to false
    counterFinished: false,
  }

export const badgeSlice = createSlice({
  name: 'badge',
  initialState,
  reducers: {
    setIsPreBottom: (state) => {
        state.isAtPreBottom = true;
    },
    setIsNotPreBottom: (state) => {
        state.isAtPreBottom = false;
    },
    setLoaderShown: (state, action) => {
      state.loaderShown = action.payload
    },
    setHomeBadgeLoaded: (state, action) => {
      state.homeBadgeLoaded = action.payload
    },
    setCounterFinished: (state, action) => {
      state.counterFinished = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setIsPreBottom, setIsNotPreBottom, setLoaderShown, setHomeBadgeLoaded, setCounterFinished } = badgeSlice.actions
export default badgeSlice.reducer
