import { forwardRef } from "react";
import Link from "next/link";

import classes from "./Button.module.scss";
import cn from "classnames";

const Button = ({ className: css, parentClassName: parentCss, ...props }) => {
  const isLinkButton = props.href && props.href !== "#";

  const colorVariantClass = props.color
    ? `color${props.color.charAt(0).toUpperCase() + props.color.slice(1)}`
    : "";
    
  const bgColorVariantClass =
    props.color && props.bg
      ? `bg${props.color.charAt(0).toUpperCase() + props.color.slice(1)}`
      : "";

  // icon images must be in "button-icon-iconName" format
  const iconClassPart = props.icon
    ? props.icon.split(".")[0].replace("button-icon-", "")
    : "";
  const iconVariantClass = props.icon
    ? `icon${iconClassPart.charAt(0).toUpperCase() + iconClassPart.slice(1)}`
    : "";

  const buttonUtilityClasses = {
    [classes.xsmall]: props.xsmall,
    [classes.small]: props.small,
    [classes.big]: props.big,
    [classes[colorVariantClass]]: colorVariantClass,
    [classes.bg]: props.bg && !bgColorVariantClass,
    [classes[bgColorVariantClass]]: bgColorVariantClass,
    [classes.icon]: props.icon,
    [classes[iconVariantClass]]: iconVariantClass,
    [classes.disabled]: props.disabled,
  };

  return (
    <>
      {isLinkButton ? (
        <Link href={props.href} passHref className={cn(classes.buttonLink, parentCss)}>
          <span className={cn(classes.button, buttonUtilityClasses, css)} onClick={props.onClick}>
            <span className={classes.innerSpan}>{props.children}</span>
          </span>
        </Link>
      ) : (
        <span className={cn(classes.button, buttonUtilityClasses, css)} onClick={props.onClick}>
          <span className={classes.innerSpan}>{props.children}</span>
        </span>
      )}
    </>
  );
};

export default Button;
