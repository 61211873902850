import MainNavigation from "./header/MainNavigation";
import Footer from "./footer/Footer";
import classes from "./Layout.module.scss";
import { useEffect, useState, useLayoutEffect, Component } from "react";
import MobileNavigation from "./header/MobileNavigation";
import cn from "classnames";
import dynamic from "next/dynamic";
import CookieBox from "./CookieBox";
import { useRouter } from "next/router";
import { useSelector, useDispatch } from "react-redux";
import { setLoaderShown, setHomeBadgeLoaded } from "../../store/ui/badge-store";
import { setAnimateContactArrow } from "/store/ui/ui-store";
import {
  useIsDesktop,
  useIsTablet,
  useIsAboveTablet,
} from "/hooks/useMediaQuery";

import {
  setContactFromInternship,
  setLearnSectionAnimated,
} from "../../store/ui/internship-store";
import {
  setContactSectionAnimated,
  setJobsSectionAnimated,
} from "../../store/ui/ui-store";

import sal from "sal.js";
// import InternshipPopup from "../internship/InternshipPopup";

import routes from "./routes";
// import TopBarInternship from "./header/TopBarInternship";
// import TopBarLiMeetup from "./header/TopBarLiMeetup";

const LoadAdditionalScripts = dynamic(
  () => import("../elements/LoadAdditionalScripts"),
  { ssr: false }
);

const Layout = (props) => {
  const [hideMenus, setHideMenus] = useState(false);
  const [hideFooter, setHideFooter] = useState(false);
  const [bodyClass, setBodyClass] = useState();
  const [menuItems, setMenuItems] = useState(routes.default.menuItems);
  const [menuButtons, setMenuButtons] = useState(routes.default.menuButtons);
  const [Morphs, setMorphs] = useState(() => null);

  const isDesktop = useIsDesktop();
  const isTablet = useIsTablet();
  const isAboveTablet = useIsAboveTablet();

  const router = useRouter();

  const dispatch = useDispatch();
  const loaderShown = useSelector((state) => state.badge.loaderShown);
  const homeBadgeLoaded = useSelector((state) => state.badge.homeBadgeLoaded);

  // if user enters any page but home, turn off loader
  useEffect(() => {
    if (!loaderShown && router.route !== "/") {
      dispatch(setLoaderShown(true));
      dispatch(setHomeBadgeLoaded(true));
    }
  }, [loaderShown, router.route, dispatch]);

  // set body class, routes rules are defined in ./routes.js
  useEffect(() => {
    let bodyName = "";
    const route = router.route;
    const routesRules = routes[route];

    if (routesRules && routesRules.bodyClass) {
      bodyName = routesRules.bodyClass;
    } else {
      // JS URL API won't work on server side
      let path = route.split("/");

      if (path.length) {
        bodyName = path[1]; // first token is always ""
      }
    }

    setBodyClass(bodyName);

    // setHideMenus(bodyName === "devox"); // TODO - move to ./routes.js
    // setHideFooter(bodyName === "devox");
  }, [router.route]);

  // routes rules are defined in ./routes.js
  useEffect(() => {
    const route = router.route;
    const routesRules = routes[route];
    let morphsTimeout;
    const morphsTimeoutValue = 300;
    let morphsComponent = null;

    if (routesRules && routesRules.menuItems) {
      setMenuItems(routesRules.menuItems);
    } else {
      setMenuItems(routes.default.menuItems);
    }

    if (routesRules && routesRules.menuButtons) {
      setMenuButtons(routesRules.menuButtons);
    } else {
      setMenuButtons(routes.default.menuButtons);
    }

    if (routesRules && routesRules.morphs) {
      morphsComponent = routesRules.morphs;
    } else {
      if (routesRules?.morphs === null) {
        morphsComponent = null;
      } else {
        morphsComponent = routes.default.morphs;
      }
    }

    // TODO move to ./routes.js
    if(route === "/geecon-2023" || route === "/devoxx-2023") {
      setHideMenus(true);
      setHideFooter(true);
    }

    morphsTimeout = setTimeout(() => {
      setMorphs(() => morphsComponent);
    }, morphsTimeoutValue);
  }, [router.route]);

  useEffect(() => {
    const beforeRouteChange = () => {
      dispatch(setAnimateContactArrow(false));
    };

    const afterRouteChange = () => {
      if (router.route !== "/contact") {
        dispatch(setContactFromInternship(false));
      }

      dispatch(setJobsSectionAnimated(false));
      dispatch(setContactSectionAnimated(false));
      dispatch(setLearnSectionAnimated(false));
    };

    router.events.on("routeChangeStart", beforeRouteChange);
    router.events.on("routeChangeComplete", afterRouteChange);

    return () => {
      router.events.off("routeChangeStart", beforeRouteChange);
      router.events.off("routeChangeComplete", afterRouteChange);
    };
  }, [dispatch, router.events, router.route]);

  useEffect(() => {
    const loadSal = () => {
      sal({
        once: true,
      });
    };

    if (router.route !== "/") {
      loadSal();
    } else {
      if (homeBadgeLoaded) {
        loadSal();
      }
    }
  }, [router.route, homeBadgeLoaded]);

  const navigationProps = {
    menuItems: menuItems,
    menuButtons: menuButtons,
  };

  return (
    <>
      {/*{router.route !== "/internship" && <TopBarInternship />}*/}
      {/*{router.route !== "/lead-up" && <TopBarLiMeetup />}*/}

      <div
        className={cn(classes.layout, {
          [classes.home]: router.route === "/",
          [classes.overflowHidden]: router.route === "/404",
        })}
      >
        {isTablet && !hideMenus && <MobileNavigation {...navigationProps} />}
        {isAboveTablet && !hideMenus && <MainNavigation {...navigationProps} />}

        <div className={cn(classes.mainWrapper)}>
          <main className={classes.main} id="main">
            {props.children}
          </main>
        </div>

        {/* <InternshipPopup /> */}
        <CookieBox />

        {loaderShown && !hideFooter && <Footer />}
      </div>


      {/* Wistia scripts (needed for morphs on desktop) */}
      {(isDesktop || router.route === "/internship") && (
        <LoadAdditionalScripts />
      )}

      {/* Morphs */}
      {isDesktop && Morphs && <Morphs />}

      <div className={classes.noiseWrapper} />
    </>
  );
};
// Layout.whyDidYouRender = true;
export default Layout;
