import cn from "classnames";
import classes from "./Input.module.scss";

export default function Input({
  placeholder,
  id,
  name,
  type,
  register,
  required,
  errors,
  className: extraClasses,
  onFocus,
  onBlur
}) {
  const displayedLabel = required ? `${placeholder}*` : placeholder;
  const inputHidden = type === "hidden";

  return (
    <div className={cn(classes.inputWrapper, extraClasses, { [classes.error]: errors, [classes.inputWrapperHidden]: inputHidden })}>
      {!inputHidden ? (
        <>
          <input
            {...register(name)}
            id={id}
            name={name}
            type={type || "text"}
            placeholder={displayedLabel}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          <label htmlFor={id}>{displayedLabel}</label>
        </>
      ) : (
        // <div className={cn(classes.inputWrapper, classes.inputHidden, { [classes.error]: errors })}>
        <input
          {...register(name)}
          id={id}
          name={name}
          type="hidden"
        />
        // </div>
      )}

      {errors && <div className={classes.errorsWrapper}>{errors.message}</div>}
    </div>
  );
}
