import Footer from "./footer/Footer";
import LogoHeader from "./header/LogoHeader";
import classes from "./FullPageLayout.module.scss";
import cn from "classnames";
import CookieBox from "./CookieBox";
import dynamic from "next/dynamic";

export default function FullPageLayout(props) {
  return (
    <>
      <div>
        {/* <LogoHeader /> */}
        <div className={cn(classes.mainWrapper)}>
          <main className={classes.main} id="main">
            {props.children}
          </main>
        </div>
        <CookieBox />
      </div>
      <div className={classes.noiseWrapper} />
    </>
  );
}
