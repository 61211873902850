import Link from "next/link";
import Image from "next/image";
import classes from "./LogoHeader.module.scss";

//images
import codewiseLogo from "../../../public/images/codewise-logo.svg";

export default function LogoHeader(props) {
  return (
    <header id="header" className={classes.logoHeader}>
      <div className={classes.inner}>
        <div className={classes.logo}>
          <Link href="/">

            <Image src={codewiseLogo} alt="Codewise" priority />

          </Link>
        </div>
      </div>
    </header>
  );
}
