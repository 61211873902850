import Link from "next/link";
import classes from "./FooterLegal.module.scss";
import tiLogo from "../../../public/images/connected-by-team-internet-logo.svg";
import Image from "next/image";

export default function FooterLegal(props) {
  return (
    <div className={classes.footerLegal}>
      <div className={classes.copyLogoCol}>
        <Image src={tiLogo} alt={"Connected by Team Internet"}/>
        <span className={classes.copy}>
          &copy; Commerce Media Tech {new Date().getFullYear()}
        </span>
      </div>
      <nav>
        <ul>
          <li>
            <Link href="/privacy-policy" target="_blank">Privacy Policy</Link>
          </li>
          <li>
            <Link href="/cookies-policy" target="_blank">Cookies Policy</Link>
          </li>
          <li>
            <Link href="/security" target="_blank">Security</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}
