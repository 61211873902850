import { useState, useEffect } from "react";

export function useMediaQuery(query) {
  const [matches, setMatches] = useState();

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };

    if (media.addEventListener) {
      media.addEventListener('change', listener);
      return () => media.removeEventListener('change', listener);
    } else {
      media.addListener(listener);
      return () => media.removeListener(listener);
    }
  }, [matches, query]);

  return matches;
}

export const useIsSmall = () => useMediaQuery("screen and (max-width: 479px)");
export const useIsMedium = () => useMediaQuery("screen and (max-width: 767px)");
export const useIsAboveMedium = () => useMediaQuery("screen and (min-width: 768px)");
export const useIsNotMobile = () => useMediaQuery("screen and (min-width: 960px)");
export const useIsTablet = () => useMediaQuery("screen and (max-width: 959px)");
export const useIsAboveTablet = () => useMediaQuery("screen and (min-width: 960px)");
export const useIsBelowSmallDesktop = () => useMediaQuery("screen and (max-width: 1150px)");
export const useIsSmallDesktop = () => useMediaQuery("screen and (min-width: 1151px)");
export const useIsBelowDesktop = () => useMediaQuery("screen and (max-width: 1534px)");
export const useIsDesktop = () => useMediaQuery("screen and (min-width: 1535px)");
export const useIsFhd = () => useMediaQuery("screen and (min-width: 1920px)");
