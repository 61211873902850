import cn from "classnames";
import classes from "./Select.module.scss";
import { v4 as uuidv4 } from "uuid";

export default function Select({
  id,
  name,
  register,
  required,
  errors,
  className: extraClasses,
  onFocus,
  onBlur,
  values,
  children,
}) {
  //   const displayedLabel = required ? `${placeholder}*` : placeholder;

  return (
    <div
      className={cn(classes.inputWrapper, extraClasses, {
        [classes.error]: errors,
      })}
    >
      <>
        <select
          {...register(name)}
          id={id}
          name={name}
          onFocus={onFocus}
          onBlur={onBlur}
        >
          <option value="" disabled>
            {/* TODO: add translation */}
            Proszę wybrać
          </option>

          {values.map(({ value, label }, index) => (
            <option value={value} key={`${value}_${index}}`}>
              {label}
            </option>
          ))}
        </select>
        <label htmlFor={id}>{children}</label>
      </>

      {errors && <div className={classes.errorsWrapper}>{errors.message}</div>}
    </div>
  );
}
