import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";

import { useRef, useState, useEffect } from "react";
import cn from "classnames";
import { ActiveLink, Button, Dropdown } from "../../elements";
import throttle from "lodash/throttle";

import classes from "./MobileNavigation.module.scss";
import { useSelector } from "react-redux";

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

//images
import codewiseLogo from "../../../public/images/cmtech-logo.svg";

export default function MobileNavigation(props) {
  const [menuToggled, setMenuToggled] = useState(false);
  const homeBadgeLoaded = useSelector((state) => state.badge.homeBadgeLoaded);
  const loaderShown = useSelector((state) => state.badge.loaderShown);
  const topBarDisplayed = useSelector((state) => state.ui.topBarDisplayed);
  const [isFixed, setIsFixed] = useState(false);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuToggled(!menuToggled);
  };

  const toggleMenuOnKeyPress = (e) => {
    if (e.key === "Enter") {
      setMenuToggled(!menuToggled);
    }
  };

  const menuToggleRef = useRef(null);

  const router = useRouter();

  const handleScroll = (e) => {
    if (window.scrollY > 0) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  const throttledScrollHandler = throttle(handleScroll, 200);

  useEffect(() => {
    throttledScrollHandler();

    window.addEventListener("scroll", throttledScrollHandler, {
      passive: true,
    });

    // cleanup
    return () => {
      window.removeEventListener("scroll", throttledScrollHandler);
    };
  }, [isFixed, throttledScrollHandler]);

  // close menu when link inside menu is clicked and new page loads
  useEffect(() => {
    const body = document.querySelector("body");

    const handleRouteChange = (url, { shallow }) => {
      if (menuToggled) {
        setMenuToggled(false);
      }
    };

    if (menuToggled) {
      body.classList.add("mobile-menu-opened");
    } else {
      body.classList.remove("mobile-menu-opened");
    }

    router.events.on("routeChangeComplete", handleRouteChange);
    router.events.on("hashChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      router.events.off("hashChangeComplete", handleRouteChange);
    };
  }, [menuToggled, router.events]);

  // block body scrolling if mobile menu is opened
  useEffect(() => {
    const header = document.querySelector("#header");

    if (menuToggled) {
      disableBodyScroll(header);
    } else if (loaderShown) {
      enableBodyScroll(header);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [menuToggled, loaderShown]);

  return <>
    <header
      className={cn(classes.header, {
        [classes.headerScrolled]: isFixed,
        [classes.headerOpened]: menuToggled,
        [classes.headerShownAfterLoader]: homeBadgeLoaded,
        [classes.headerWithTopBarOffset]: topBarDisplayed,
      })}
      id="header"
    >
      <Link href="/">
        <Image src={codewiseLogo} alt="Commerce Media Tech Logo" priority className={classes.logo} />
      </Link>
      <div
        ref={menuToggleRef}
        onClick={toggleMenu}
        onKeyDown={toggleMenuOnKeyPress}
        className={cn(classes.toggle, { [classes.toggleOpen]: menuToggled })}
        tabIndex={0}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </header>
    {menuToggled && (
      <div className={classes.menu}>
        {props.menuItems && (
          <nav>
            <ul>
              {props.menuItems.map((item, index) => {
                return item.dropdownItems ? (
                  <li key={item.label + index}>
                    <Dropdown title={item.label}>
                      {item.dropdownItems.map((item, index) => (
                        <ActiveLink key={item.label + index} href={item.link}>
                          {item.label}
                        </ActiveLink>
                      ))}
                    </Dropdown>
                  </li>
                ) : (
                  <li key={index}>
                    <ActiveLink href={item.link}>{item.label}</ActiveLink>
                  </li>
                );
              })}
            </ul>
          </nav>
        )}
        {props.menuButtons && (
          <div className={classes.buttonWrapper}>
            {props.menuButtons.map((item, index) => (
              <Button
                href={item.link}
                key={index}
                bg={item.bg}
                icon={item.icon}
              >
                {item.label}
              </Button>
            ))}
          </div>
        )}
      </div>
    )}
  </>;
}
