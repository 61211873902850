import { createSlice } from "@reduxjs/toolkit";

import AsiaImg from "/public/images/internship/asia-image.png";
import KrystianImg from "/public/images/internship/krystian-image.png";
import StaszekImg from "/public/images/internship/staszek-image.png";
import BartekImg from "/public/images/internship/bartek-image.png";
import BartekImg2 from "/public/images/internship/bartek-image2.png";
import Kamila1Img from "/public/images/internship/kamila1-image.png";
import Kamila2Img from "/public/images/internship/kamila2-image.png";

// make sure that all small images for slides2023 have the same height!
import AsiaSmallImg from "/public/images/internship/asia-small.png";
import KrystianSmallImg from "/public/images/internship/krystian-small.png";
import StaszekSmallImg from "/public/images/internship/staszek-small.png";
import BartekSmallImg from "/public/images/internship/bartek-small.png";
import BartekSmallImg2 from "/public/images/internship/bartek-small2.png";
import Kamila1SmallImg from "/public/images/internship/kamila1-small.png";
import Kamila2SmallImg from "/public/images/internship/kamila2-small.png";


export const slideAsia = {
  id: 0,
  title: "Be like Asia!",
  position: "Software Engineering Expert",
  team: "Publisher Team, Zeropark",
  desc: "Do a <strong>paid internship</strong> at <br/>Codewise and become <br/>a <strong>software developer</strong>.",
  videoId: "h0dhln3ozk",
  imgBig: AsiaImg,
  imgSmall: AsiaSmallImg,
  videoDuration: "0:42",
};

export const slideKrystian = {
  id: 1,
  title: "Be like Krystian!",
  position: "Tech Lead",
  team: "Publisher Team, Zeropark",
  desc: "Do a <strong>paid internship</strong> at <br/>Codewise and become <br/>a <strong>software developer</strong>.",
  videoId: "62n59z1baw",
  imgBig: KrystianImg,
  imgSmall: KrystianSmallImg,
  videoDuration: "0:41",
};

export const slideStaszek = {
  id: 2,
  title: "Be like Staszek!",
  position: "Software Engineering Expert",
  team: "Advertiser Team, Zeropark",
  desc: "Do a <strong>paid internship</strong> at <br/>Codewise and become <br/>a <strong>software developer</strong>.",
  videoId: "38cnhah1w5",
  imgBig: StaszekImg,
  imgSmall: StaszekSmallImg,
  videoDuration: "0:44",
};

export const slideBartek = {
  id: 3,
  title: "Be like <span>Bartek!</span>",
  desc: "Do a <strong>paid internship</strong> at <br/>Codewise in Cracow<br />and become a <strong>software developer</strong>.",
  videoId: "e134sb6nk2",
  imgBig: BartekImg2,
  imgSmall: BartekSmallImg2,
  videoDuration: "0:44",
};

export const slideKamila1= {
  id: 4,
  title: "Be like <span>Kamila!</span>",
  desc: "Do a <strong>paid internship</strong> at <br/>Codewise in Cracow<br />and become a <strong>backend developer</strong>.",
  imgBig: Kamila1Img,
  imgSmall: Kamila1SmallImg,
};

export const slideKamila2= {
  id: 5,
  title: "Be like <span>Kamila!</span>",
  desc: "Do a <strong>paid internship</strong> at <br/>Codewise in Cracow<br />and become a <strong>backend developer</strong>.",
  imgBig: Kamila2Img,
  imgSmall: Kamila2SmallImg,
};



const initialState = {
  contactFromInternship: false,
  slides: { asia: slideAsia, krystian: slideKrystian, staszek: slideStaszek },
  slides2023: [slideBartek, slideKamila1, slideKamila2],
  activeSlide: null,
  activeSlide2023: slideBartek,
  currentVideoId: "",
  videoIsPlaying: false,
  thumbClick: false,
  learnSectionAnimated: false,
  animateArrow: false,
  popupVisible: false,
  notifyPopupShown: false,
  notifyFormSent: false,
};

export const internshipSlice = createSlice({
  name: "internship",
  initialState,
  reducers: {
    setContactFromInternship: (state, action) => {
      state.contactFromInternship = action.payload;
    },
    setActiveSlide: (state, action) => {
      state.activeSlide = action.payload;
    },
    setActiveSlide2023: (state, action) => {
      state.activeSlide2023 = action.payload;
    },
    setCurrentVideoId: (state, action) => {
      state.currentVideoId = action.payload;
    },
    setVideoIsPlaying: (state, action) => {
      state.videoIsPlaying = action.payload;
    },
    setThumbClick: (state, action) => {
      state.thumbClick = action.payload;
    },
    setLearnSectionAnimated: (state, action) => {
      state.learnSectionAnimated = action.payload;
    },
    setAnimateArrow: (state, action) => {
      state.animateArrow = action.payload;
    },
    setPopupVisible: (state, action) => {
      state.popupVisible = action.payload;
      state.notifyPopupShown = true;
    },
    setNotifyFormSent: (state, action) => {
      state.notifyFormSent = action.payload;
    },
  },
});

export const selectContactFromInternship = (state) => {
  return state.internship.contactFromInternship;
};

// Action creators are generated for each case reducer function
export const {
  setContactFromInternship,
  setActiveSlide,
  setActiveSlide2023,
  setCurrentVideoId,
  setVideoIsPlaying,
  setThumbClick,
  setLearnSectionAnimated,
  setAnimateArrow,
  setPopupVisible,
  setNotifyFormSent,
} = internshipSlice.actions;
export default internshipSlice.reducer;
