import FooterLegal from "./FooterLegal";
import FooterMenu from "./FooterMenu";
import classes from "./Footer.module.scss";
import FooterBadge from "./FooterBadge";
import { InView } from "react-intersection-observer";
import { onFooterIntersect } from '/helpers/badgeHelpers';

export default function Footer(props) {

  return (
    <InView
      as="div"
      onChange={onFooterIntersect}
      rootMargin="0px 0px 400px 0px"
    >
      <footer className={classes.footer} id="footer">
        <FooterBadge />
        <FooterMenu />
        <FooterLegal />
      </footer>
    </InView>
  );
}
