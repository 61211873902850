import Footer from "./footer/Footer";
import LogoHeader from "./header/LogoHeader";
import classes from "./EbeaLayout.module.scss";
import cn from "classnames";
import CookieBox from "./CookieBox";
import dynamic from "next/dynamic";

const LoadAdditionalScripts = dynamic(
  () => import("../elements/LoadAdditionalScripts"),
  { ssr: false }
);

export default function EbeaLayout(props) {
  return (
    <>
      <div>
        <LogoHeader />
        <div className={cn(classes.mainWrapper)}>
          <main className={classes.main} id="main">
            {props.children}
          </main>
        </div>
        <CookieBox />
		<LoadAdditionalScripts />
        <Footer />
      </div>
      <div className={classes.noiseWrapper} />
    </>
  );
}
