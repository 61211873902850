import cn from 'classnames';
import classes from "./FileInput.module.scss";

export default function FileInput({
  name,
  id,
  placeholder,
  accept,
  required,
  register,
  errors,
  onChange,
  onFocus,
  onBlur
}) {
  const displayedLabel = required ? `${placeholder}*` : placeholder;

  return (
    <div className={cn(classes.inputWrapper, { [classes.error]: errors })}>
      <input
        {...register(name)}
        id={id}
        name={name}
        type="file"
        accept={accept}
        placeholder={displayedLabel}
        // onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <div className={classes.labelWrapper}>
        <label htmlFor={id}>{displayedLabel}</label>
      </div>

      {errors && (
        <div className={classes.errorsWrapper}>
          {errors.message}
        </div>
      )}
    </div>
  );
}
