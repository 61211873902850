import Image from "next/image";
import cn from "classnames";
import classes from "./Dropdown.module.scss";
import linkClasses from "./ActiveLink.module.scss";
import { useState, useRef } from "react";
import { useIsTablet } from "../../hooks/useMediaQuery";

import arrowUpIcon from "../../public/images/careers/arrow-up.svg";

const Dropdown = (props) => {
  const [opened, setOpened] = useState(false);
  const isTablet = useIsTablet();
  const boxRef = useRef();
  const triggerRef = useRef();

  const handleClick = (e) => {
    e.preventDefault();
    triggerRef.current.blur();
    if(isTablet) {
      setOpened(!opened);
    }
  };

  const removeFocus = (e) => {
    document.activeElement?.blur();
  }

  return (
    <div
      className={cn(classes.dropdownWrapper, {
        [classes.opened]: opened,
      })}
    >
      <span
        tabIndex="0"
        className={cn(classes.dropdownTrigger, linkClasses.link)}
        onClick={handleClick}
        ref={triggerRef}
      >
        {props.title}
        <Image src={arrowUpIcon} alt="" />
      </span>
      <div className={classes.dropdownBox} onClick={removeFocus} ref={boxRef}>
        {props.children}
      </div>
    </div>
  );
};

export default Dropdown;
