import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	questionNumber: 0,
	timerStatus: "STARTED",
	isOverTime: false,
	isFinalMsgVisible: false,
};

export const devoxQuizSlice = createSlice({
	name: "devoxQuiz",
	initialState,
	reducers: {
		setFinalMsg: (state, action) => {
			state.isFinalMsgVisible = action.payload;
		},
		setQuestionNumber: (state, action) => {
			state.questionNumber = action.payload;
		},
		startTimer: (state) => {
			state.timerStatus = "STARTED";
		},
		stopTimer: (state) => {
			state.timerStatus = "STOPPED";
		},
		setIsOverTime: (state, action) => {
			state.isOverTime = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setQuestionNumber, startTimer, stopTimer, setIsOverTime, setFinalMsg } = devoxQuizSlice.actions;
export default devoxQuizSlice.reducer;
