import "../wdyr"; // <--- first import

import { useRouter } from "next/router";
// import { useEffect } from "react";
import { DefaultSeo } from "next-seo";

import { store } from "/store/store";
import { Provider } from "react-redux";
import Script from "next/script";

import NextNprogress from "nextjs-progressbar";
// import "intersection-observer-debugger";
import "../styles/globals.scss";

import Head from "next/head";
import useSystemTheme from "use-system-theme";
import DefaultLayout from "../components/layout/Layout";
import EbeaLayout from "../components/layout/EbeaLayout";
import FullPageLayout from "../components/layout/FullPageLayout";

const layouts = {
  EbeaLayout: EbeaLayout,
  FullPageLayout: FullPageLayout,
};

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const systemTheme = useSystemTheme();

  // useEffect(() => {
  //   const storage = globalThis?.sessionStorage;
  //   if (!storage) return;
  //   // Set the previous path as the value of the current path.
  //   const prevPath = storage.getItem("currentPath");
  //   storage.setItem("prevPath", prevPath);
  //   // Set the current path value by looking at the browser's location object.
  //   storage.setItem("currentPath", globalThis.location.pathname);
  // }, [router.asPath]);

  // use Layout defined on page level or default
  const Layout = layouts[Component.layout] || DefaultLayout;

  return (
    <Provider store={store}>
      <Head>
        {systemTheme !== "dark" && (
          <>
            <link
              rel="apple-touch-icon"
              sizes="152x152"
              href="/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/favicon-16x16.png"
            />
            <link
              rel="icon"
              href="/favicon.ico"
              type="image/x-icon"
            />
          </>
        )}

        {systemTheme === "dark" && (
          <>
            <link
              rel="apple-touch-icon"
              sizes="152x152"
              href="/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/favicon-16x16.png"
            />
            <link
              rel="icon"
              href="/favicon.ico"
              type="image/x-icon"
            />
          </>
        )}
      </Head>
      <Script
        id="gtm"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-NQZ6L3');
  `,
        }}
      />
      <>
        {router.pathname === "/geecon2022/quiz" ? (
          <Component {...pageProps} />
        ) : (
          <Layout>
            <NextNprogress options={{ showSpinner: false }} />
            <DefaultSeo
              title="Commerce Media Tech"
              description="Game-Changing Tools for Digital Marketers"
              openGraph={{
                title: "Commerce Media Tech",
                description: "Game-Changing Tools for Digital Marketers",
                images: [
                  {
                    url: "/images/cmt-og.png",
                    width: 1200,
                    height: 630,
                    alt: "Commerce Media Tech",
                    type: "image/png",
                  },
                ],
              }}
            />
            <Component {...pageProps} />
          </Layout>
        )}
      </>
    </Provider>
  );
}

export default MyApp;
